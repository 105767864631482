/* eslint-disable no-console */
import React, { useRef, useState, useEffect } from 'react';

import PropTypes from 'prop-types';

const BgAudioPlayer = ({ src }) => {
  const audioRef = useRef(null);
  const [volume, setVolume] = useState(1);
  const [audioUrl, setAudioUrl] = useState('');

  const handleVolumeChange = event => {
    const newVolume = event.target.value;
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current
        .play()
        .then(() => {
          console.log('Audio is playing muted');
        })
        .catch(error => {
          console.log('Autoplay failed:', error);
        });
    }

    const unmuteAudio = () => {
      if (audioRef.current) {
        audioRef.current.muted = false;
        audioRef.current.play();
      }
      document.removeEventListener('click', unmuteAudio);
    };

    document.addEventListener('click', unmuteAudio);

    return () => {
      document.removeEventListener('click', unmuteAudio);
    };
  }, [src]);

  useEffect(() => {
    const fetchAudio = async () => {
      try {
        const dbRequest = indexedDB.open('kananco');

        dbRequest.onsuccess = event => {
          const db = event.target.result;
          const transaction = db.transaction('downloads', 'readonly');
          const store = transaction.objectStore('downloads');
          const index = store.index('url');
          const getRequest = index.get(src);

          getRequest.onsuccess = event => {
            const result = event.target.result;
            if (result && result.blob) {
              const blob = result.blob;
              const url = URL.createObjectURL(blob);
              setAudioUrl(url);
            } else {
              console.log('Audio not found');
            }
          };

          getRequest.onerror = event => {
            console.error('Error retrieving audio:', event.target.error);
          };
        };

        dbRequest.onerror = event => {
          console.error('Error opening database:', event.target.error);
        };
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (src) fetchAudio();
  }, [src]);

  if (!audioUrl) {
    return;
  }

  return (
    <div className="audio-player">
      <audio
        ref={audioRef}
        src={audioUrl}
        autoPlay />
      <div className="volume-control">
        <p>
          <span className="fas fa-volume"></span>
        </p>
        <input
          type="range"
          id="volume"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
        />
      </div>
    </div>
  );
};

BgAudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
};

export default BgAudioPlayer;
