import { toast } from 'react-toastify';

import { downloadTestEngineLaunch, syncOfflineTracks } from './action-engine';
import { openInNewTab } from '../../constants/url';
import { checkOnline } from '../../hooks/useNetworkStatus';
import {
  getTestsByCode,
  getTestToken,
  putBulkData,
  getTestsAccessByCode,
  getAllLocalTests,
  deleteDexieTestAndTracks,
  resetTestById,
} from '../../lib/dexie';
import Service from '../../lib/Service';
import { testActions as types } from '../actionTypes';

export const testDownload = ({ testId, courseId }) => (dispatch) => {
  const isOnline = checkOnline();

  if (!isOnline) {
    toast.error(
      'Please check your internet connection and try downloading again later',
    );
    return;
  }

  dispatch(downloadTestEngineLaunch({
    testId,
    courseId,
  }));
};

export const setDownloadTokens = (testId, isRemove) => (dispatch) => {
  dispatch({
    type: types.SET_DOWNLOAD_TOKENS,
    payload: {
      testId, isRemove
    }
  });
};

export const setTestAccordion = (activeGrp, expandAccordion) => (dispatch) => {
  dispatch({
    type: types.SET_TEST_ACCORDION,
    payload: { activeGrp, expandAccordion }
  });
};

const getNewTests = (oldData, newData, user) => {

  const tests = [];

  for (let index = 0; index < newData.length; index++) {
    const group = newData[index];
    const tempGrp = {
      ...group,
      _id: group.id,
      userId: user.id,
      isGroup: true
    };
    delete tempGrp.id;
    delete tempGrp.subItems;

    const subItems = group.subItems.map(sGrp => {
      const tempSgrp = {
        ...sGrp,
        _id: sGrp.id,
        userId: user.id,
      };
      delete tempSgrp.id;
      delete tempSgrp.subItems;
      return tempSgrp;
    });

    tests.push(tempGrp);
    tests.push(...subItems);
  }

  let oldTestIds = [];

  if (oldData?._id) {
    oldTestIds = [oldData._id, ...oldData.subItems.map(i => i._id)].filter(i => i);
  }

  return {
    tests,
    newTests: tests.filter(test => !oldTestIds.includes(test._id))
  };
};

export const fetchTestByCode = testCode => async (dispatch, getState) => {
  try {
    const isOnline = checkOnline();
    if (!isOnline) {
      toast.error('You\'re not connected to network');
      return;
    }
    const { user } = getState().auth;

    dispatch({
      type: types.FETCH_TEST,
      payload: { loading: true, tests: [] },
    });

    let resData = [];
    const code = testCode.toLowerCase();
    let offlineResponse = await getTestsByCode(code, user.id);

    if (isOnline) {
      resData = await Service.GET({
        name: 'testbycode',
        queryString: `testCode=${code}`,
      }).then(res => {
        if (res.success) {
          return res.data;
        }
        dispatch(getAllTests());
        throw new Error(res.message);
      });
    } else {
      resData = offlineResponse?.id ? [offlineResponse] : [];
    }

    if (resData.length === 0) {
      throw new Error('Invalid group code.');
    }

    const { newTests, tests } = getNewTests(offlineResponse, resData, user);

    if (newTests.length > 0) {
      await putBulkData('tests', newTests);

      // Download Test
      newTests.filter(i => !i.isGroup).map(sItem => {
        dispatch(
          testDownload({
            testId: sItem._id,
            courseId: sItem.courseId,
          }),
        );
      });

      const group = tests.find(t => t.isGroup);

      if (group?._id) {
        dispatch(setTestAccordion({ ...group, id: group._id }, true));
      }
    }

    dispatch({
      type: types.FETCH_TEST,
      payload: { loading: false },
    });

    dispatch(getAllTests());

    if (newTests.length === 0) {
      toast.success('All data is already up-to-date');
    }
  } catch (error) {
    toast.error(error.message);
    dispatch({
      type: types.FETCH_TEST,
      payload: { error: error.message, tests: [], loading: false },
    });
  }
};

export const getAllTests = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: types.FETCH_TEST,
      payload: { loading: true, tests: [] },
    });
    const { user } = getState().auth;
    const { activeGrp } = getState().test;

    const data = await getAllLocalTests(user.id);

    const groups = data.filter(d => d.isGroup);

    const tests = groups.map(grp => {
      let subItems = data.filter(d => d.parentId === grp.id).sort((a, b) => a.orderNo - b.orderNo);
      return {
        ...grp,
        subItems: subItems
      };
    });

    let payload = {};

    if (!activeGrp?.id && tests.length > 0) {
      payload.activeGrp = tests[0];
      payload.expandAccordion = true;
    }

    dispatch({
      type: types.FETCH_TEST,
      payload: { tests: tests, loading: false, ...payload },
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_TEST,
      payload: { error: error.message, tests: [], loading: false },
    });
  }
};

export const initTest = params => async (dispatch, getState) => {
  try {
    const testId = params.testId;
    const courseId = params.courseId;
    const { user } = getState().auth;

    dispatch({
      type: types.INIT_TEST,
      payload: { initiating: true, initRefId: testId, error: null },
    });

    const isOnline = checkOnline();

    let resData = null;

    const offlineResponse = await getTestToken({
      testId,
      courseId,
      userId: user.id,
    });

    if (!offlineResponse.token) {
      toast.error('Test download failed');
      throw new Error('Test download failed');
    }

    if (isOnline) {
      await dispatch(syncOfflineTracks({ ...params, redirectToken: offlineResponse.token }));
      resData = await Service.POST({
        name: 'test',
        payload: JSON.stringify({
          groupId: 0,
          testId: testId,
          courseId: courseId,
        }),
      }).then(res => {
        if (res.success) {
          return res.data;
        }
        throw new Error(res.message);
      });

      openInNewTab(`/test-engine/token/validate/${resData.redirectToken}`);
    } else {
      if (!offlineResponse.success) {
        throw new Error('Failed to launch Test');
      }

      openInNewTab(`/test-engine?token=${offlineResponse.token}`);
    }

    dispatch({
      type: types.INIT_TEST,
      payload: { initiating: false, initRefId: null, error: null },
    });
  } catch (error) {
    dispatch({
      type: types.INIT_TEST,
      payload: { initiating: false, initRefId: null, error: error.message },
    });
  }
};

export const setLaunchDetails = () => ({
  type: types.SET_LAUNCH_DETAILS,
  payload: { launching: false, launchError: null },
});

export const launchTestByCode = (testCode) => async (dispatch, getState) => {
  try {
    dispatch({
      type: types.LAUNCH_TEST_BY_CODE,
      payload: { launching: true, launchError: null },
    });

    const { user } = getState().auth;
    const code = testCode.toLowerCase();
    const testData = await getTestsAccessByCode(code, user.id);

    if (!testData?.id) {
      throw new Error('Invalid Access Code. Please contact your administrator');
    }

    if (testData.studentTestDetails?.isActive === 0) {
      throw new Error('Test was completed');
    }

    await dispatch(
      initTest({
        testId: testData.id,
        courseId: testData.courseId,
      }),
    );

    dispatch({
      type: types.LAUNCH_TEST_BY_CODE,
      payload: { launching: false, launchError: null },
    });
  } catch (error) {
    dispatch({
      type: types.LAUNCH_TEST_BY_CODE,
      payload: { launching: false, launchError: error.message },
    });
  }
};

export const deleteTest = test => async (dispatch, getState) => {
  try {
    dispatch({
      type: types.DELETE_TEST,
      payload: { deleting: true, deleteId: test.id },
    });
    const { user } = getState().auth;

    const deleted = await deleteDexieTestAndTracks(test, user.id);

    if (!deleted) {
      throw new Error('Delete Failed');
    }

    dispatch(getAllTests());

    setTimeout(() => {
      dispatch({
        type: types.DELETE_TEST,
        payload: { deleting: false, deleteId: null },
      });
    }, 1000);
  } catch (error) {
    toast.error(error.message);
    dispatch({
      type: types.DELETE_TEST,
      payload: { deleting: false, deleteId: null },
    });
  }
};

export const resetTest = ({ testId }) => async (dispatch, getState) => {
  const { user } = getState().auth;

  await resetTestById(testId, user.id);
};