import React, { useState } from 'react';
import { menus } from '../../../constants/data';
import { NavLink } from 'react-router-dom';
import packageParams from '../../../../package.json';
import { COMPANY_LOGO } from '../../../constants/config';

const Sidebar = () => {
  const [activeMenu, setActiveMenu] = useState('');

  return (
    <div id="sidebar" className="sidebar">
      <a
        href="/"
        className="logo-wrapper d-flex align-items-center pb-4 mb-md-0 me-md-auto">
        <img className="logo" src={COMPANY_LOGO} alt="kanan.co" />
      </a>
      <ul
        className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-sm-start"
        id="menu">
        {menus.map(menu => {
          return (
            <li
              className={`nav-item ${activeMenu === menu.id ? 'active' : ''}`}
              key={menu.id}>
              <NavLink
                key={menu.id}
                to={menu.url}
                className={({ isActive }) => {
                  return isActive && menu.url !== '#'
                    ? 'nav-link active'
                    : 'nav-link';
                }}
                onClick={event => {
                  setActiveMenu(menu.id);
                }}>
                <i className={menu.icon} />
                <span>{menu.name}</span>
              </NavLink>
              {!!menu.subMenus.length && (
                <ul
                  className={`collapse ${activeMenu === menu.id ? 'show' : ''} nav flex-column ms-3`}
                  id="submenu1">
                  {menu.subMenus.map(sMenu => (
                    <li className="w-100" key={sMenu.id}>
                      <NavLink
                        key={sMenu.id}
                        to={`/${sMenu.url}`}
                        className={'nav-link'}
                        onClick={() => {
                          setActiveMenu(sMenu.parentId);
                        }}>
                        <i className={menu.icon} />
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
      <p className="app-version">{`Build - v${packageParams.version}`}</p>
    </div>
  );
};

export default Sidebar;
